import React from 'react';

import ItemActions from '@wix/photography-client-lib/dist/src/item/itemActions';
import TPADimensionsHelper from '@wix/pro-gallery-tpa-wrapper/dist/src/helpers/TPADimensionsHelper';

import { SENTRY_DSN } from '../../constants';

// import prependHttpExtra from 'prepend-http-extra';
// import PropTypes from 'prop-types';
// import { translate } from 'react-i18next';
// import s from './GalleryWrapper.scss';
import { ProGallery } from '@wix/pro-gallery-renderer';
import EVENTS from '@wix/pro-gallery-renderer/dist/src/utils/constants/events';
import { cssScrollHelper } from '@wix/pro-gallery-renderer/dist/src/components/helpers/cssScrollHelper';
import { getProGalleryStyles } from '@wix/photography-client-lib/dist/src/utils/proGalleryStyleBuilder';
import window from '@wix/photography-client-lib/dist/src/sdk/windowWrapper';
import Consts from '@wix/photography-client-lib/dist/src/utils/consts';
import experiments, {
  experimentsWrapper,
} from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
// import { sentryUtils } from '@wix/photography-client-lib/dist/src/utils/sentryUtils';
import './WixStyles.scss';
import '../FullscreenWrapper/WixStyles.scss';
import './GalleryWrapper.scss';
import { utils } from '../../utils';
import { pgVersionManager } from '@wix/photography-client-lib/dist/src/versioning/proGalleryVersionManager';
import { parseStyleParams } from '@wix/photography-client-lib/dist/src/fullscreen/parseStyleParams.js';
import { logger } from '@wix/photography-client-lib/dist/src/utils/biLogger';
import translationUtils from '@wix/photography-client-lib/dist/src/utils/translationUtils';
import fullscreenHelper from '../../helpers/fullscreenHelper';
import { resizeMediaUrl } from '@wix/photography-client-lib/dist/src/item/itemResizer';

const PgContext = React.createContext({});

export default class ProGallerySantaWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedIdx: -1,
      directFullscreenItem: this.props.directFullscreenItem,
      hasFullscreenElement: false,
      accessibility: {
        tabWasPressed: false,
        isAccessible: false,
      },
      itemsLoveData: {},
    };
    this.getScrollingElement = this.getScrollingElement.bind(this);
    this.animatedOpenFullscreen = this.animatedOpenFullscreen.bind(this);
    this.animatedCloseFullscreen = this.animatedCloseFullscreen.bind(this);
    this.componentDidLayout = this.componentDidLayout.bind(this);
    this.waitForFirstTabPress = this.waitForFirstTabPress.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.updateFullscreenCurrentItem = this.updateFullscreenCurrentItem.bind(
      this,
    );
    this.onLoveCountsFetched = this.onLoveCountsFetched.bind(this);

    this.pgTiming = { [Date.now()]: 'Wrapper Constructed' };
    this.onNewProps(props);
    this.FullscreenElement = null;
    this.isDevAccessibility = utils.shouldDebug('accessibility');

    this.itemActions = new ItemActions();
  }

  onLoveCountsFetched(photoId2Love) {
    const itemsLoveData = {};
    Object.entries(photoId2Love).forEach(([id, loveCount]) => {
      itemsLoveData[id] = {
        loveCount,
        isLoved: this.itemActions.isLoved(id),
      };
    });
    this.setState({ itemsLoveData });
  }

  componentDidMount() {
    // this.pgTiming.wrapperMounted = Date.now();
    this.pgTiming[Date.now()] = 'Wrapper Mounted';
    const {
      galleryId,
      styleId,
      pageId,
      id,
      baseUrl,
      pageUrl,
      fullscreenUrl,
      instanceId,
      loadFonts,
      style,
      registerToComponentDidLayout,
    } = this.props;
    this.loadUserFonts(loadFonts, style.styleParams);
    this.itemActions.initWidgetData({
      compId: id,
      pageId,
      styleId,
      galleryId,
      baseUrl,
      pageUrl,
      fullscreenUrl,
      instanceId,
      onLoveCountsFetched: this.onLoveCountsFetched,
    });

    registerToComponentDidLayout(this.componentDidLayout);

    if (this.props.accessibilityEnabled || this.isDevAccessibility) {
      window.addEventListener('keydown', this.waitForFirstTabPress);
    }

    this.onNewProps(this.props);

    if (!utils.isSSR()) {
      import('../FullscreenWrapper/FullscreenWrapper').then(
        ProFullscreenWrapper => {
          this.FullscreenElement = ProFullscreenWrapper.default;
          this.setState({ hasFullscreenElement: true });
        },
      );
    }
  }

  componentDidLayout() {
    this.alreadyDidLayout = true;
    if (this.firstItemCreated || this.zeroItems) {
      this.onAppLoaded();
    }
  }

  componentWillReceiveProps(props) {
    this.onNewProps(props);
  }

  componentWillUnmount() {
    if (this.props.accessibilityEnabled || this.isDevAccessibility) {
      window.removeEventListener('keydown', this.waitForFirstTabPress);
    }
  }

  loadUserFonts(loadFonts, styleParams) {
    try {
      const fontFamilies = Object.values(styleParams.fonts)
        .map(font => font.family)
        .filter(family => !!family)
        .join(',')
        .split(',');
      loadFonts(fontFamilies);
    } catch (e) {
      console.warn('Failed loading custom fonts');
    }
  }

  waitForFirstTabPress = event => {
    if (event.keyCode === 9) {
      window.removeEventListener('keydown', this.waitForFirstTabPress);
      //window.addEventListener('keydown', this.waitForFirstTabPress) happen only if this.props.accessibilityEnabled || this.isDevAccessibility
      const accessibility = Object.assign({}, this.state.accessibility, {
        tabWasPressed: true,
        isAccessible: true,
      });
      this.setState({
        accessibility,
      });
    }
  };

  onNewProps(props) {
    Object.assign(this.pgTiming, props.pgTiming);
    this.updateVersionManagerIfNeeded(props);

    translationUtils.setTranslations(props.translations);
    experimentsWrapper.setExperiments({
      ...props.experiments,
    });

    if (window.isSSR) {
      const isMobile = this.isMobileDevice(props.formFactor);
      window.deviceType = isMobile ? 'mobile' : 'desktop';
      utils.setIsWixMobile(isMobile);
    }

    utils.updateViewMode(props.viewMode);
    if (
      this.state.accessibility.isAccessible !==
      ((props.accessibilityEnabled || this.isDevAccessibility) &&
        this.state.accessibility.tabWasPressed)
    ) {
      const accessibility = Object.assign({}, this.state.accessibility, {
        isAccessible:
          (props.accessibilityEnabled || this.isDevAccessibility) &&
          this.state.accessibility.tabWasPressed,
      });
      this.setState({
        accessibility,
      });
    }
  }

  captureMessage(str) {
    const options = {
      dsn: SENTRY_DSN,
      config: { environment: 'Native Component' },
    };

    try {
      const { raven } = this.props;
      raven.config(options.dsn, options.config);
      raven.captureMessage(str);
    } catch (e) {
      //
    }
  }

  onItemCreated() {
    this.firstItemCreated = true;
    if (this.alreadyDidLayout) {
      this.onAppLoaded();
    }
  }

  onAppLoaded = () => {
    try {
      if (!utils.isSSR() && !this.props.notInView) {
        this.props.reportAppLoaded();
      }
      if (!this.appLoadBiReported) {
        if (
          this.props.appLoadBI &&
          typeof this.props.appLoadBI.loaded === 'function'
        ) {
          this.props.appLoadBI.loaded();
          this.appLoadBiReported = true;
        } else {
          const err =
            'Cannot report AppLoaded, appLoadBI.loaded function is not defined';
          console.error(err);
          this.captureMessage(err);
        }
      }
      if (utils.isVerbose()) {
        this.pgTiming[Date.now()] = 'Wrapper Render End';
        const pgTableStr = this.calcPgTimings();
        if (console.table) {
          console.table(pgTableStr);
        } else {
          console.log('Pro Gallery Timings');
          console.log(pgTableStr);
        }
      }
    } catch (e) {
      console.error('Could not report appLoaded', e);
    }
  };

  onItemActionTriggered(itemProps, styleParams) {
    const itemClick = styleParams.itemClick;
    const itemClickProps = {
      dto: this.pgItemsProps().items.find(item => item.itemId === itemProps.id),
      id: itemProps.id,
      idx: itemProps.idx,
    };
    this.props.onItemClicked(itemClickProps, itemClick);
    if (itemClick === 'fullscreen') {
      this.toggleBrowserFullscreen();
      this.animatedOpenFullscreen(itemClickProps);
    } else if (itemClick === 'expand') {
      this.animatedOpenFullscreen(itemClickProps);
    }
  }

  onCurrentItemChanged(item) {
    this.props.onCurrentItemChanged(item);
  }

  reportBiEvent(eventName, eventData) {
    switch (eventName) {
      case 'onDownloadButtonClicked':
        logger.trackBi(logger.biEvents.download, {
          origin: 'gallery',
          gallery_id: this.props.galleryId,
        });
        break;
      case 'onBuyNowClicked':
        logger.trackBi(logger.biEvents.buyNowClick);
        break;
      case 'onItemClicked':
        logger.trackBi(logger.biEvents.item_clicked, {
          action: eventData.styleParams.itemClick,
          media: eventData.type,
          layout: utils.getGalleryLayoutName(
            eventData.styleParams.galleryLayout,
          ),
          gallery_id: this.props.galleryId,
        });
        break;
      case 'onThumbnailClicked':
        logger.trackBi(logger.biEvents.item_clicked, {
          action: 'thumbnail',
          media: undefined,
          layout: utils.getGalleryLayoutName(
            eventData.styleParams.galleryLayout,
          ),
          gallery_id: this.props.galleryId,
        });
        break;
    }
  }

  handleItemActions(action, actionData) {
    let item;
    switch (action) {
      case 'share':
        this.itemActions.share(
          actionData.network,
          actionData.shareProps,
          'gallery',
        );
        break;
      case 'showTooltip':
        this.itemActions.showTooltip(actionData.event, actionData.key);
        break;
      case 'hideTooltip':
        this.itemActions.hideTooltip();
        break;
      case 'downloadTextItem':
        item = {
          html: actionData.html,
          style: actionData.style,
        };
        this.itemActions.downloadTextItem(item, 'gallery');
        break;
      case 'postLoveActivity':
        item = {
          type: actionData.type,
          itemId: actionData.itemId,
          id: actionData.id,
          idx: actionData.idx,
          hashtag: actionData.hashtag,
        };
        this.itemActions.postLoveActivity(item);
        break;
      case 'toggleLove':
        this.itemActions.toggleLove(actionData.id, actionData.layout);
        break;
    }
  }

  animatedCloseFullscreen(itemIdx, animationDuration = 800) {
    //scroll to the item
    let y;
    try {
      if (itemIdx >= 0) {
        const itemDto = this.props.items[itemIdx];
        const item = { id: itemDto.itemId };
        const itemDomId = cssScrollHelper.getDomId(item);
        const itemContainer = window.document.getElementById(itemDomId);
        const rect = itemContainer.getBoundingClientRect();
        const padding = (window.innerHeight - rect.height) / 2;
        y = window.scrollY + rect.y - padding;
        if (y >= 0) {
          this.props.scrollTo(0, y);
        }
        itemContainer.focus();
      }
    } catch (e) {
      console.warn('Could find last fullscreen item', itemIdx, e);
    }
    //close the fullscreen
    // this.setState({
    //   clickedIdx: -1,
    // });
    // animate close
    this.setState(
      {
        fullscreenAnimating: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            directFullscreenItem: undefined,
            clickedIdx: -1,
          });
        }, animationDuration);
      },
    );
  }

  updateFullscreenCurrentItem(item) {
    if (this.props.isInSEO && item) {
      this.props.setMetaTags(item);
    }
  }

  toggleBrowserFullscreen() {
    if (
      !window.document.fullscreenElement &&
      !window.document.mozFullScreenElement &&
      !window.document.webkitFullscreenElement &&
      !window.document.msFullscreenElement
    ) {
      // current working methods
      if (window.document.documentElement.requestFullscreen) {
        window.document.documentElement.requestFullscreen();
      } else if (window.document.documentElement.msRequestFullscreen) {
        window.document.documentElement.msRequestFullscreen();
      } else if (window.document.documentElement.mozRequestFullScreen) {
        window.document.documentElement.mozRequestFullScreen();
      } else if (window.document.documentElement.webkitRequestFullscreen) {
        window.document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
      }
    } else {
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
      } else if (window.document.msExitFullscreen) {
        window.document.msExitFullscreen();
      } else if (window.document.mozCancelFullScreen) {
        window.document.mozCancelFullScreen();
      } else if (window.document.webkitExitFullscreen) {
        window.document.webkitExitFullscreen();
      }
    }
  }

  animatedOpenFullscreen(itemProps) {
    const expandAnimation = Consts.expandAnimations.FADE_IN; // styleParams.expandAnimation
    if (
      expandAnimation === Consts.expandAnimations.EXPAND ||
      expandAnimation === Consts.expandAnimations.ZOOM
    ) {
      const itemDom = window.document.querySelector(
        `#${cssScrollHelper.getDomId(itemProps)}`,
      );
      const itemDomHover = window.document.querySelector(
        `#${cssScrollHelper.getDomId(itemProps)} .gallery-item-hover`,
      );
      if (!itemDom) return;
      let rect;
      let origFsItemStyle;
      try {
        rect = itemDom.getBoundingClientRect();
        origFsItemStyle = Object.assign({}, itemDom.style);
        Object.assign(itemDomHover.style, {
          visibility: 'hidden',
        });
        if (expandAnimation === Consts.expandAnimations.EXPAND) {
          Object.assign(itemDom.style, {
            zIndex: 9999999,
            position: 'fixed',
            transition: 'none',
            top: 0,
            left: 0,
            transform: `translate3d(${rect.left}px, ${rect.top}px, 0)`,
            width: rect.width + 'px',
            height: rect.height + 'px',
          });
        }
      } catch (e) {
        //
      }
      this.setState(
        {
          fullscreenAnimating: true,
          clickedIdx: this.getFullscreenIndex(itemProps),
        },
        () => {
          try {
            const body = window.document.querySelector(
              `#pro-gallery-${this.props.id}`,
            );
            const origBodyStyle = Object.assign({}, body.style);
            const fsItemDom = window.document.querySelector(
              '.fullscreen-current .fullscreen-content',
            );
            const fsRect = fsItemDom.getBoundingClientRect();
            const scale = Math.min(
              fsRect.width / rect.width,
              fsRect.height / rect.height,
            );
            const dY = Math.round(
              fsRect.top + fsRect.height / 2 - (rect.top + rect.height / 2),
            );
            const dX = Math.round(
              fsRect.left + fsRect.width / 2 - (rect.left + rect.width / 2),
            );
            if (expandAnimation === Consts.expandAnimations.EXPAND) {
              Object.assign(itemDom.style, {
                zIndex: 9999999,
                position: 'fixed',
                transform: `translate3d(${rect.left + dX}px,${rect.top +
                  dY}px, 0) scale(${scale})`,
                filter: 'opacity(0)',
                transition: 'transform .4s ease, filter 1.5s ease',
              });
            } else if (expandAnimation === Consts.expandAnimations.ZOOM) {
              Object.assign(body.style, {
                transform: `translate3d(${dX}px,${dY}px, 0) scale(${scale})`,
                // filter: 'opacity(0)',
                transition: 'transform .4s ease, filter 1.5s ease',
              });
            }
            this.setState({
              fullscreenAnimating: false,
            });
            setTimeout(() => {
              if (expandAnimation === Consts.expandAnimations.EXPAND) {
                itemDom.style.top = origFsItemStyle.top;
                itemDom.style.left = origFsItemStyle.left;
                itemDom.style.width = origFsItemStyle.width;
                itemDom.style.height = origFsItemStyle.height;
                itemDom.style.zIndex = origFsItemStyle.zIndex;
                itemDom.style.filter = origFsItemStyle.filter;
                itemDom.style.position = origFsItemStyle.position;
                itemDom.style.transform = origFsItemStyle.transform;
                itemDom.style.transition = origFsItemStyle.transition;
              } else if (expandAnimation === Consts.expandAnimations.ZOOM) {
                body.style.filter = origBodyStyle.filter;
                body.style.position = origBodyStyle.position;
                body.style.transform = origBodyStyle.transform;
                body.style.transition = origBodyStyle.transition;
              }
              itemDomHover.style.visibility = 'visible';
            }, 2000);
          } catch (e) {
            //
          }
        },
      );
    }

    if (expandAnimation === Consts.expandAnimations.FADE_IN) {
      this.setState(
        {
          fullscreenAnimating: true,
          clickedIdx: this.getFullscreenIndex(itemProps),
        },
        () => {
          this.setState({
            fullscreenAnimating: false,
          });
        },
      );
    } else {
      this.setState({
        fullscreenAnimating: false,
        clickedIdx: this.getFullscreenIndex(itemProps),
      });
    }
  }

  getFullscreenIndex(itemProps) {
    const { totalItemsCount } = this.pgItemsProps();
    if (itemProps.idx > 0 && totalItemsCount > 0) {
      return itemProps.idx % totalItemsCount;
    }
    return itemProps.idx;
  }

  getScrollingElement() {
    if (typeof this.scrollingElement === 'object') {
      return this.scrollingElement;
    } else if (typeof this.props.registerToScroll === 'function') {
      this.scrollingElement = {
        addEventListener: (eventName, callback) => {
          this.props.registerToScroll(callback);
        },
        removeEventListener: () => {},
        scrollTo: this.props.scrollTo,
      };
      return this.scrollingElement;
    } else {
      return {
        addEventListener: () => {},
        removeEventListener: () => {},
        scrollTo: this.props.scrollTo,
      };
    }
  }

  updateVersionManagerIfNeeded(props) {
    try {
      const { dateCreated, gallerySettings } = props;
      const _dateCreated = Date.parse(dateCreated);
      if (pgVersionManager.dateCreated !== _dateCreated) {
        if (_dateCreated) {
          pgVersionManager.setDateCreated(_dateCreated);
          if (gallerySettings) {
            const _gallerySettings = JSON.parse(gallerySettings);
            const galleryUpgrades = _gallerySettings.upgrades;
            pgVersionManager.update(null, galleryUpgrades);
          }
          if (utils.isDev()) {
            window.dateCreated = dateCreated;
          }
        }
      }
    } catch (e) {
      console.error('Could not update version manager', e);
    }
  }

  calcPgTimings() {
    // this.pgTiming.wrapperRenderEnd = Date.now();
    const pgTiming = { ...this.pgTiming };
    let pgTableStr = Object.entries(pgTiming);

    pgTableStr = pgTableStr.sort((e1, e2) => e1[0] - e2[0]);
    pgTableStr = pgTableStr.map((e, idx, entries) => {
      if (idx > 0) {
        return { [e[1]]: e[0] - entries[idx - 1][0] };
      } else {
        return { [e[1]]: 0 };
      }
    });
    pgTableStr = pgTableStr.map(
      e =>
        Object.entries(e).map(_e => ({
          label: _e[0],
          millis: Number(_e[1]),
        }))[0],
    );
    pgTableStr = pgTableStr.sort((e1, e2) => e1.millis - e2.millis);
    return pgTableStr;
  }

  isMobileDevice(formFactor) {
    //santa options: mobile/desktop
    //bolt options: smartphone/desktop/tablet
    const deviceType = String(formFactor).toLowerCase();
    return deviceType === 'mobile' || deviceType === 'smartphone';
  }

  pgItemsProps = () => {
    let items, totalItemsCount;

    if (!this.props.wixCodeItems) {
      items = this.props.items || [];
      totalItemsCount = this.props.totalItemsCount || items.length || 1;
    } else {
      items = this.props.wixCodeItems.map(item => {
        if (typeof item.metaData === 'string') {
          try {
            const newItem = {
              ...item,
              metaData: JSON.parse(item.metaData),
            };
            return newItem;
          } catch (e) {
            console.error('Failed parse item metaData', e);
          }
        }
        return item;
      });
      totalItemsCount = items.length;
    }
    return {
      items,
      totalItemsCount,
    };
  };

  fullscreenItemsProps = () => {
    let items, totalItemsCount;
    if (this.state.directFullscreenItem) {
      items = [this.state.directFullscreenItem];
      totalItemsCount = 1;
      return {
        items,
        totalItemsCount,
      };
    } else {
      return this.pgItemsProps();
    }
  };

  getMoreItems(from) {
    if (!this.props.wixCodeItems && this.props.getMoreItems) {
      this.props.getMoreItems(from);
    }
  }

  handleNewGalleryStructure(newGalleryStructureData) {
    const {
      numOfItems,
      container,
      partialStyleParams,
      layoutHeight,
      isInfinite,
      updatedHeight,
    } = newGalleryStructureData;

    const setHeightImp = newHeight => {
      if (typeof this.props.setHeight === 'function') {
        if (utils.isVerbose()) {
          console.log('Setting new height for gallery', newHeight);
        }
        this.props.setHeight(newHeight);
      }
    };

    TPADimensionsHelper.setWixHeight({
      height: layoutHeight,
      offsetTop: 0,
      partialStyleParams,
      container,
      numOfItems,
      isInfinite,
      updatedHeight,
      setHeightImp,
      viewMode: this.props.viewMode,
    });

    try {
      this.props.updateLayout();
    } catch (e) {
      console.log('Cannot call updateLayout', e);
    }
  }

  handleEvent = (eventName, eventData) => {
    switch (eventName) {
      case EVENTS.ITEM_CREATED:
        this.onItemCreated();
        break;
      case EVENTS.ITEM_ACTION_TRIGGERED:
        this.onItemActionTriggered(eventData, this.pgGalleryStyles);
        break;
      case EVENTS.CURRENT_ITEM_CHANGED:
        this.onCurrentItemChanged(eventData);
        break;
      case EVENTS.GALLERY_CHANGE:
        this.handleNewGalleryStructure(eventData);
        break;
      case EVENTS.NEED_MORE_ITEMS:
        this.getMoreItems(eventData);
        break;
      case EVENTS.SHARE_BUTTON_CLICKED:
        this.handleItemActions('share', eventData);
        break;
      case EVENTS.TEXT_DOWNLOAD_BUTTON_CLICKED:
        this.handleItemActions('downloadTextItem', eventData);
        break;
      case EVENTS.LOVE_BUTTON_CLICKED:
        this.handleItemActions('postLoveActivity', eventData);
        this.handleItemActions('toggleLove', eventData);
        break;
      case EVENTS.ITEM_CLICKED:
        this.reportBiEvent('onItemClicked', eventData);
        break;
      case EVENTS.DOWNLOAD_BUTTON_CLICKED:
        this.reportBiEvent('onDownloadButtonClicked', eventData);
        break;
      case EVENTS.BUY_NOW_CLICKED:
        this.reportBiEvent('onBuyNowClicked', eventData);
        break;
      case EVENTS.THUMBNAIL_CLICKED:
        this.reportBiEvent('onThumbnailClicked', eventData);
        break;
      default:
        if (utils.isVerbose()) {
          console.log(eventName, 'is not handled in handleEvent function');
        }
    }
  };

  render() {
    const { queryParams, notInView } = this.props;

    // this.pgTiming.wrapperRenderStart = Date.now();
    this.pgTiming[Date.now()] = 'Wrapper Render Start';
    if (
      window.isSSR &&
      ((experiments && experiments('specs.pro-gallery.skipSsr') === 'true') ||
        (queryParams && queryParams.skipPgSsr === 'true'))
    ) {
      console.error('Skipping Pro Gallery SSR!', this.props);
      return <div />;
    }

    if (utils.isSSR() && notInView) {
      if (utils.isVerbose()) {
        console.log('PG not in view, skipping');
      }
      return <div id="pg-not-in-view" />;
    }

    if (utils.isVerbose()) {
      console.log('Pro Gallery wrapper!', this.props);
      console.count('[OOISSR] proGallery ooi wrapper render');
    }
    const {
      id,
      galleryId,
      dimensions: { width, height },
      style,
      formFactor,
      forceHover,
      manualStyleParams,
      registerToComponentDidLayout,
      baseUrl,
      viewMode,
    } = this.props;

    const isPremiumSite =
      baseUrl && baseUrl.includes && !baseUrl.includes('.wixsite.com'); // this is a hack until we have a normal way to know
    const isMobile = this.isMobileDevice(formFactor) || utils.isMobile();
    const deviceType = isMobile ? 'mobile' : 'desktop';

    const { styleParams } = style;
    this.pgGalleryStyles = getProGalleryStyles(
      { ...styleParams, ...manualStyleParams },
      { isMobile },
    );
    this.pgGalleryStyles.isAccessible = this.state.accessibility.isAccessible;

    if (
      isMobile &&
      !window.isSSR &&
      typeof this.pgGalleryStyles.gallerySize === 'number'
    ) {
      //compensate for wix's 320px fix for mobile
      const fixRatio = 320 / window.screen.width;
      this.pgGalleryStyles.gallerySize *= fixRatio;
      this.pgGalleryStyles.imageMargin *= fixRatio;
    }
    this.pgProps = {
      domId: id,
      galleryId,
      allowSSR: true,
      container: { width, height },
      forceHover,
      isPremiumSite: isPremiumSite,
      registerToComponentDidLayout,
      isInSEO: this.props.isInSEO,
      viewMode,
      scrollingElement: this.getScrollingElement(),
      itemsLoveData: this.state.itemsLoveData,
      resizeMediaUrl,
    };

    if (this.pgItemsProps().length === 0) {
      this.zeroItems = true;
    }

    const pgContextValue = { isMobile };
    const dom = [
      <PgContext.Provider key="provider" value={pgContextValue}>
        <ProGallery
          key="pro-gallery"
          ref={node => (this.node = node)}
          styles={this.pgGalleryStyles}
          eventsListener={this.handleEvent}
          // customInfoRenderer={props => {
          //   return <div>customInfoRenderer {props.idx}</div>;
          // }}
          {...this.pgProps}
          {...this.pgItemsProps()}
        />
      </PgContext.Provider>,
    ];
    if (
      !utils.isSSR() &&
      this.state.hasFullscreenElement &&
      this.FullscreenElement
    ) {
      const ProFullscreenWrapper = this.FullscreenElement;
      dom.push(
        <ProFullscreenWrapper
          {...this.pgProps}
          {...this.fullscreenItemsProps()}
          key="pro-fullscreen"
          styleParams={parseStyleParams(styleParams)}
          scrollTo={this.props.scrollTo}
          fullscreenAnimating={this.state.fullscreenAnimating}
          fullscreenIdx={
            this.state.directFullscreenItem ? 0 : this.state.clickedIdx
          }
          closeFullscreen={this.animatedCloseFullscreen}
          toggleBrowserFullscreen={this.toggleBrowserFullscreen}
          onLinkNavigation={this.props.onLinkNavigation}
          itemActions={this.itemActions}
          updateCurrentItem={this.updateFullscreenCurrentItem}
        />,
      );
    }

    try {
      if (window.isSSR) {
        const pgTableStr = this.calcPgTimings();
        const pgTimingStr = Object.entries(this.pgTiming).map(e =>
          e.join(': '),
        );

        dom.push(
          <div key="pg-render-time" style={{ display: 'none' }}>
            <p>Pro Gallery Timing:</p>
            <ol>
              {pgTimingStr.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ol>
            <ol>
              {pgTableStr.map((e, i) => (
                <li key={i}>{`${e.label}: ${e.millis}`}</li>
              ))}
            </ol>
            <p>
              Device Type:
              <ol>
                <li>this.props.formFactor: {this.props.formFactor}</li>
                <li>formFactor: {formFactor}</li>
                <li>deviceType: {deviceType}</li>
                <li>isMobile: {isMobile}</li>
                <li>utils.isMobile(): {String(utils.isMobile())}</li>
                <li>utils.isWixMobile(): {String(utils.isWixMobile())}</li>
                <li>window.deviceType: {window.deviceType}</li>
              </ol>
            </p>
            <p>
              Query Params:
              {Object.entries(queryParams)
                .map(entry => entry.join(':'))
                .join(', ')}
            </p>
          </div>,
        );
      }
    } catch (e) {
      //
    }

    return (
      <div data-key={`gallery-wrapper-${id}`} key={`gallery-wrapper-${id}`}>
        {dom}
      </div>
    );
  }
}
